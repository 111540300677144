import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MuiLink from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MenuIcon from '@material-ui/icons/Menu';

import logo from "../assets/images/logo.png";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: "#000000",
    boxShadow: "none",
  },
  posNav: {
    flexGrow: 1,
    fontFamily: ["Ubuntu", "sans-serif"].join(","),
    fontWeight: "600",
  },
  itemMenu: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)"
    },
  }
}));

export default function Navbar() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" className={classes.main}>
      <Toolbar>
        <Grid container alignItems="center">
          <Box style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <Grid item>
              <Box mr={1}>
                <img src={logo} width="57px" height="50px" alt="Logo website" />
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h5" className={classes.posNav}>
                RAIDBOT
              </Typography>
            </Grid>
          </Box>
          <Hidden smDown>
            <Grid item xs={1}>
              <MuiLink href="https://discord.com/invite/d5Gz6Kn" style={{ textDecoration: "none" }} target="_blank" rel="noopener">
                <Typography variant="subtitle1" style={{ color: "#FFFFFF", fontWeight: "700", }}>Discord</Typography>
              </MuiLink>
            </Grid>
            <Grid item xs={1}>
              <MuiLink href="https://community.raidbot.app" style={{ textDecoration: "none" }} target="_blank" rel="noopener">
                <Typography variant="subtitle1" style={{ color: "#FFFFFF", fontWeight: "700", }}>Forum</Typography>
              </MuiLink>
            </Grid>
            <Grid item xs={1}>
              <MuiLink href="https://community.raidbot.app/index.php?/topic/18-ultimate-raidbot-guide/" style={{ textDecoration: "none" }} rel="noopener" target="_blank">
                <Typography variant="subtitle1" style={{ color: "#FFFFFF", fontWeight: "700", }}>Bot Guide</Typography>
              </MuiLink>
            </Grid>
            <Grid item xs={1}>
              <MuiLink href="https://community.raidbot.app/index.php?/forum/3-raidbot-releases/" style={{ textDecoration: "none" }} rel="noopener" target="_blank">
                <Typography variant="subtitle1" style={{ color: "#FFFFFF", fontWeight: "700", }}>Download</Typography>
              </MuiLink>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <IconButton onClick={handleClick}>
              <MenuIcon style={{ color: "#FFFFFF" }} />
            </IconButton>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem className={classes.itemMenu}><MuiLink href="https://discord.com/invite/d5Gz6Kn" target="_blank" rel="noopener" style={{ color: "#000000", textDecoration: "none", fontWeight: "600", }}>
                Discord
                </MuiLink>
              </MenuItem>
              <MenuItem className={classes.itemMenu}><MuiLink href="https://community.raidbot.app" target="_blank" rel="noopener" style={{ color: "#000000", textDecoration: "none", fontWeight: "600", }}>
                Forum
                </MuiLink>
              </MenuItem>
              <MenuItem className={classes.itemMenu}><MuiLink href="https://community.raidbot.app/index.php?/topic/18-ultimate-raidbot-guide/" rel="noopener" target="_blank" style={{ color: "#000000", textDecoration: "none", fontWeight: "600", }}>
                Bot Guide
                </MuiLink>
              </MenuItem>
              <MenuItem className={classes.itemMenu}> <MuiLink href="https://community.raidbot.app/index.php?/forum/3-raidbot-releases/" rel="noopener" target="_blank" style={{ color: "#000000", textDecoration: "none", fontWeight: "600", }}>
                Download
                </MuiLink>
              </MenuItem>
            </Menu>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
